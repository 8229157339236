import * as scheme from './scheme/materials.gql';

export default {
  getMaterials(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIALS,
      variables: {
        ...variables,
        kinds: ['school'],
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  getVariantsMaterials(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_VARIANTS_MATERIALS,
      variables: {
        ...variables,
        page: 1,
        kinds: ['school'],
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  getMaterialsStatistic(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIALS_STATISTIC,
      variables: {
        ...variables,
        tags: ['synergy media'],
        page: 1,
        perPage: 8,
        kinds: ['school'],
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  // для сео нужно в url вывести slug материала, поэтому проверяется
  // id является slug (String) или id (Number)
  getMaterial(id) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIAL,
      variables: {
        [Number.isNaN(+id) ? 'slug' : 'id']: id,
      },
    })
      .then(({ data }) => data?.getPublicMaterial || {});
  },

  addViewMaterial(id) {
    return this.mutate({
      mutation: scheme.ADD_VIEW_PUBLIC_MATERIAL,
      variables: { id },
    });
  },

  getPublicMaterialCategories() {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIAL_CATEGORIES,
    })
      .then((response) => response.data?.getCategories || []);
  },

  getPublicLesson(materialId) {
    return this.query({
      query: scheme.GET_PUBLIC_LESSON,
      variables: {
        id: materialId,
      },
    }).then((response) => response?.data?.getPublicMaterial || {});
  },

};
